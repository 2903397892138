import React, { useState } from 'react';
import { Box, Button, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, Avatar, FormHelperText } from '@mui/material';
import MainCard from '../../../ui-elements/cards/MainCard';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as Yup from 'yup';
import { TextField } from 'formik-mui'
import { Formik, Form, Field } from 'formik';
import { makeStyles } from 'tss-react/mui';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { toast } from 'react-toastify'


const NotificationPage = () => {
    const [message, setMessage] = useState('');
    const [globalSearchTerm, setGlobalSearchTerm] = useState(''); // Global search term state
    const { classes } = useStyles();

    const handleSendNotificationToAllUsers = async ({ title, body, action }, { setSubmitting }) => {
        try {
            if (title != "" && body != "") {

                const functions = getFunctions();
                const sendNotification = httpsCallable(functions, 'sendNotificationToAllUsers');
                const response = await sendNotification({ title, body, action });
                setMessage(response.data);
            } else {
                toast.success('Please fill title and body');
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error sending notification:', error);
            setMessage('Error sending notification');
        } finally {
            setSubmitting(false);
        }
    };

    const handleSendNotificationToSelectedUsers = async ({ title, body, action, selectedUsers }, { setSubmitting }) => {
        try {
            console.log(title, body);
            if (title != "" && body != "" && selectedUsers.length != 0) {
                const functions = getFunctions();
                const sendNotification = httpsCallable(functions, 'sendNotificationToAllUsersSelected');
                const response = await sendNotification({ title, body, action, selectedUsers });
                setMessage(response.data);
            } else {
                toast.success('Please fill all form.');
            }
            setSubmitting(false);

        } catch (error) {
            console.error('Error sending notification:', error);
            setMessage('Error sending notification');
            setSubmitting(false);

        } finally {
            setSubmitting(false);
        }
    };

    const handleUserSearch = async (searchTerm, setFieldValue) => {
        try {
            setGlobalSearchTerm(searchTerm); // Set the global search term
            const functions = getFunctions();
            const searchUsers = httpsCallable(functions, 'autoCompleteSearchUsers');
            const response = await searchUsers({ searchTerm: globalSearchTerm });
            console.log('response.data.users', response.data.users);
            console.log('globalSearchTerm', globalSearchTerm);
            console.log('response.data.searchTerm', response.data.searchTerm);

            // Ensure the response matches the current search term
            if (response.data.searchTerm === globalSearchTerm) {
                setFieldValue('userOptions', response.data.users);
            }
        } catch (error) {
            console.error('Error searching users:', error);

        }
    };

    return (
        <Box m={6}>
            <MainCard>
                <Formik
                    initialValues={{
                        title: '',
                        body: '',
                        action: '',
                        selectedUsers: [],
                        userOptions: [] // To hold search results
                    }}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().min(2).max(30).required('Title is required'),
                        body: Yup.string().min(2).required('Body is required'),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        if (values.selectedUsers.length > 0) {
                            handleSendNotificationToSelectedUsers(values, { setSubmitting });
                        } else {
                            handleSendNotificationToAllUsers(values, { setSubmitting });
                        }
                    }}
                >
                    {({ values, handleBlur, handleChange, touched, errors, setFieldValue, isSubmitting }) => (
                        <Form>
                            <Typography variant="h4">Send Notification:</Typography>

                            <FormControl fullWidth className={classes.formInput}>
                                <InputLabel id="outlined-action">Action</InputLabel>
                                <Select
                                    labelId="outlined-Action"
                                    variant="outlined"
                                    label="Action"
                                    value={values.action}
                                    name="action"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    sx={{ width: '100%' }}
                                >
                                    <MenuItem value="">
                                        <span style={{ marginLeft: '10px', height: '20px' }}></span>
                                    </MenuItem>
                                    <MenuItem value="upgrade">Upgrade Now</MenuItem>
                                    <MenuItem value="subscriptions">View Subscriptions</MenuItem>
                                    <MenuItem value="balance">View Balance</MenuItem>
                                    <MenuItem value="marketplace">View Marketplace</MenuItem>
                                    <MenuItem value="product">View Product</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth className={classes.formInput}>
                                <Field
                                    id="outlined-Title"
                                    type="text"
                                    value={values.title}
                                    variant="outlined"
                                    name="title"
                                    label="Title"
                                    onChange={handleChange}
                                    fullWidth
                                    component={TextField}
                                />
                                {touched.title && errors.title && (
                                    <FormHelperText error>{errors.title}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth className={classes.formInput}>
                                <Field
                                    id="outlined-Body"
                                    type="text"
                                    value={values.body}
                                    variant="outlined"
                                    name="body"
                                    label="Body"
                                    onChange={handleChange}
                                    fullWidth
                                    component={TextField}
                                    multiline
                                    rows={8}
                                />
                                {touched.body && errors.body && (
                                    <FormHelperText error>{errors.body}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth className={classes.formInput}>
                                <Autocomplete
                                    multiple
                                    options={values.userOptions || []}
                                    getOptionLabel={(option) => option.username || option.email}
                                    onInputChange={(event, value) => handleUserSearch(value, setFieldValue)}
                                    renderInput={(params) => (
                                        <Field
                                            {...params}
                                            name="searchUsers"
                                            label="Search Users"
                                            placeholder="Start typing to username or email..."
                                            component={TextField} // Use formik-mui's TextField
                                        />
                                    )}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            <Avatar src={option.photoUrl} alt={option.username} />
                                            {option.username || option.email}
                                        </li>
                                    )}
                                    onChange={(event, value) => setFieldValue('selectedUsers', value)}
                                />
                            </FormControl>
                            <Box mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSendNotificationToAllUsers(values, { setSubmitting: (isSubmitting) => { } })}
                                    disabled={isSubmitting}
                                >
                                    Send Notification to All Users
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSendNotificationToSelectedUsers(values, { setSubmitting: (isSubmitting) => { } })}
                                    disabled={isSubmitting || values.selectedUsers.length === 0}
                                    sx={{ ml: 2 }}
                                >
                                    Send Notification to Selected Users
                                </Button>
                            </Box>

                            {message && (
                                <Box mt={2}>
                                    <Typography variant="body1">{message}</Typography>
                                </Box>
                            )}
                        </Form>
                    )}
                </Formik>
            </MainCard>
        </Box>
    );
};

const useStyles = makeStyles({ "name": { NotificationPage } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    avatar: {
        margin: 'auto',

        width: '100px!important',
        height: '100px!important'

    },
    dialogHeader: {
        paddingBottom: '50px!important'
    }

}));
export default NotificationPage;
